import React from 'react';
import checkImg from 'common/src/assets/image/success.svg';
import Text from 'common/src/components/Text';

const imgStyle = {
  height: '20px',
  width: '20px',
  verticalAlign: 'sub',
  marginRight: '1rem',
};

const textStyle = {
  fontSize: '16px',
  //color: '#343d48cc',
  // color: 'darkslategrey',
  //color: '#2a313c',
  //color: 'white',
  lineHeight: '1.75',
  marginBottom: '0.7rem',
};

const CheckedText = ({ text, white = false }) => {
  return (
    <div>
      <Text
        content={text}
        iconStart={<img src={checkImg} style={imgStyle} alt="Check" />}
        style={{
          display: 'inline-block',
          color: white ? 'white' : '#343d48cc',
          ...textStyle,
        }}
      />
    </div>
  );
};

export default CheckedText;
