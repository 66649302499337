import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, {
  BackgroundShadow1,
  BackgroundShadow2,
} from './useCases.style';

const BannerSection = ({ row, title, description, contentWrapper }) => {
  return (
    <BannerWrapper>
      <Container width="85%">
        <Box {...row}>
          <Box {...contentWrapper}>
            <Heading
              {...title}
              content="Better process. Better opportunities."
            />
            <Text
              content={
                <>
                  Look beyond account-based marketing and bring structure to
                  your team, strategy and overall Sales Process. <br />
                  SalesOcean empowers Sales Team to get the best out of them
                  selfs.{' '}
                </>
              }
              {...description}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  contentWrapper: PropTypes.object,
  row: PropTypes.object,
};

BannerSection.defaultProps = {
  contentWrapper: {
    width: '100%',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    // mb: ['40px']
  },

  title: {
    fontSize: ['22px', '34px', '30px', '40px', '50px'],
    fontWeight: '700',
    color: 'rgb(46 55 77)',
    letterSpacing: '-0.025em',
    //mb: '20px',
    lineHeight: '1.5',
    textAlign: 'center',
    as: 'h1',
  },
  description: {
    fontSize: '15px',
    // color: '#b1afaf',
    lineHeight: '1.7',
    mb: '0',
    width: '80%',
    textAlign: 'center',
    margin: 'auto',
  },
};

export default BannerSection;
