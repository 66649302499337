import React from 'react';
import PropTypes from 'prop-types';
import Zoom from 'react-reveal/Zoom';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, {
  SectionObject,
  CheckColumnOne,
} from './abm.style';
import TextHr from 'common/src/components/textLine';
import ImageBg from 'common/src/assets/image/saas/visitor_bg.png';
import TaskList from 'common/src/assets/image/saas/account_planner.png';
import Fade from 'react-reveal/Fade';
import CheckedText from 'common/src/components/checkedText';
import {
  TopBootstrap,
  BottomBootstrap,
} from 'common/src/components/bootstrapView/index.style';

const VisitorSection = ({
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  sectionSubTitle,
  handleOpenDemoModal,
}) => {
  const ImgageView = () => (
    <SectionObject>
      <Card className="objectWrapper" {...imageWrapper}>
        <Zoom>
          <Image
            src={ImageBg}
            alt="BgImage"
            style={{ maxWidth: '100%', transform: 'rotateY(180deg)' }}
          />
        </Zoom>
        <Card className="dashboardWrapper" {...imageWrapper}>
          <Fade right delay={90}>
            <img
              src={TaskList}
              alt="extension view"
              style={{
                boxShadow: '7px 11px 23px 2px grey',
                borderRadius: '5px',
              }}
            />
          </Fade>
        </Card>
      </Card>
    </SectionObject>
  );

  return (
    <VisitorSectionWrapper id="accountBasedMarketing">
      <TopBootstrap>
        <ImgageView />
      </TopBootstrap>
      <Container>
        <Box {...textArea}>
          <FeatureBlock
            title={
              <>
                <TextHr />
                <Text
                  content="BEYOND ACCOUNT-BASED MARKETING"
                  {...sectionSubTitle}
                />
                <Heading
                  content={
                    <>
                      Next level
                      <br /> Account-based marketing.
                    </>
                  }
                  {...title}
                />
              </>
            }
            description={
              <div style={{ marginBottom: '20px' }}>
                <Text
                  {...description}
                  content="Reach out to your potential customers before your competitors do so.                  "
                />
                <CheckColumnOne style={{ paddingLeft: '10px' }}>
                  <CheckedText text="Plan your accounts over time and bring more structure to your sales team" />
                  <CheckedText text="Update and add data directly in the Chrome extension" />
                  <CheckedText text="Engage with multiple departments at once through our automated sequences" />
                </CheckColumnOne>
              </div>
            }
            button={
              <Button
                title="SEE IN ACTION"
                {...btnStyle}
                onClick={handleOpenDemoModal}
              />
            }
          />
        </Box>
      </Container>
      <BottomBootstrap>
        <ImgageView />
      </BottomBootstrap>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ['100%', '100%', '45%'],
    ml: [0, 0],
  },
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'left',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  imageWrapper: {
    boxShadow: 'none',
    marginTop: ['2rem', '3.5rem'],
  },
  title: {
    fontSize: ['20px', '26px', '26px', '36px', '48px'],
    fontWeight: '400',
    color: '#2a313c',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
    lineHeight: '1.5',
  },
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '1.75',
    maxWidth: ['100%', '100%', '100%', '440px', '440px'],
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
  },
};

export default VisitorSection;
